/**
 * Legal Notice! DB Systel GmbH proprietary Licence!
 *
 * Copyright (C) 2018 DB Systel GmbH
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * This code is protected by copyright law and is the exclusive property of
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * Consent to use ("licence") shall be granted solely on the basis of a
 * written licence agreement signed by the customer and DB Systel GmbH. Any
 * other use, in particular copying, redistribution, publication or
 * modification of this code without written permission of DB Systel GmbH is
 * expressly prohibited.
 *
 * In the event of any permitted copying, redistribution or publication of
 * this code, no changes in or deletion of author attribution, trademark
 * legend or copyright notice shall be made.
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { BenutzerRolle } from '../../shared/model/benutzerRolle';
import { tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private readonly apiBaseUrl = environment.apiUrl;
  private benutzerRollen: Array<BenutzerRolle> | null = null;

  constructor(
    private http: HttpClient,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  getBenutzerRollen(): Observable<Array<BenutzerRolle>> {
    if (this.benutzerRollen) {
      return of(this.benutzerRollen);
    } else {
      return this.authenticationService.getAccessToken().pipe(
        switchMap(
        (accessToken: string) => {
          const url = `${ this.apiBaseUrl }/benutzern/self/rollen`;
          const headers = new HttpHeaders()
            .set('accessToken',accessToken)
            .set('deviceId', this.retrieveDeviceId());
          return this.http.get<Array<BenutzerRolle>>(url, {
            headers: headers
          })
            .pipe(
              tap((rollen: Array<BenutzerRolle>) => {
                this.benutzerRollen = rollen;
              })
            );
        }
      ));
    }
  }

  hasRoleFbf(): boolean {
    return this.benutzerRollen?.some((role: BenutzerRolle) => BenutzerRolle.NameEnum.Fbf === role.name) || false;
  }

  hasRoleSachbearbeiter(): boolean {
    return this.benutzerRollen?.some((role: BenutzerRolle) => BenutzerRolle.NameEnum.Sachbearbeiter === role.name) || false;
  }

  retrieveDeviceId(): string {
    let deviceId = localStorage.getItem(this.getLocalStorageName());
    if (!deviceId) {
      deviceId = this.createDeviceId();
      localStorage.setItem(this.getLocalStorageName(), deviceId);
    }
    return deviceId;
  }


  private getLocalStorageName() {
    return 'ElbaWeb-DeviceId';
  }

  private createDeviceId(): string {
    return uuidv4();
  }
}

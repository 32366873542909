/**
 * Legal Notice! DB Systel GmbH proprietary Licence!
 *
 * Copyright (C) 2018 DB Systel GmbH
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * This code is protected by copyright law and is the exclusive property of
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * Consent to use ("licence") shall be granted solely on the basis of a
 * written licence agreement signed by the customer and DB Systel GmbH. Any
 * other use, in particular copying, redistribution, publication or
 * modification of this code without written permission of DB Systel GmbH is
 * expressly prohibited.
 *
 * In the event of any permitted copying, redistribution or publication of
 * this code, no changes in or deletion of author attribution, trademark
 * legend or copyright notice shall be made.
 */

import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  private readonly responseType = 'code';

  constructor(
    private readonly oAuthService: OAuthService,
  ) {
  }

  configure(clientId: string, scope: string): void {
    this.oAuthService.configure(new AuthConfig({
      // Login-Url
      loginUrl: environment.oauthServiceUrl,

      // Token-Url
      tokenEndpoint: environment.oauthTokenUrl,

      // JWT content type parameter
      customQueryParams: environment.oauthServiceParamsMap,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/auth/callback',

      // The SPA's id. Register SPA with this id at the auth-server
      clientId: clientId,

      issuer: environment.oauthIssuerUrl,

      // set the scope for the permissions the client should request
      scope: scope,

      // set to true, to receive also an id_token via OpenId Connect (OIDC) in addition to the
      // OAuth2-based access_token
      oidc: environment.useIdToken,

      // To also enable single-sign-out set the url for your auth-server's logout-endpoint here
      logoutUrl: environment.oauthLogoutUrl + '?client_id=' + clientId
        + '&redirect_uri=' + window.location.origin + '/auth/callback' + '&response_type=' + this.responseType,

      // Set a dummy secret
      dummyClientSecret: '',

      clearHashAfterLogin: true,

      // to configure your solution for code flow + PKCE you have to set the responseType to code:
      responseType: this.responseType,

      timeoutFactor: environment.oauthTimeoutFactor
    }));

    // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
    this.oAuthService.setStorage(localStorage);
  }
}

<div [formGroup]="form" class="nachweis" id="nachweisForm">

  <div class="d-flex flex-row justify-content-between">
    <h3 [ngClass]="{'elm-headline move-left': hasVerifyMarker(),'elm-headline-gray': !hasVerifyMarker() }">
      <img *ngIf="hasVerifyMarker()"
           class="verifyMarker"
           src="/assets/icons/oval.svg"
           id="changedDot"
           alt="icon">
      {{ headline |translate }}
    </h3>
  </div>

  <div class="d-flex global-error" *ngIf="validationInfo.documentNotUpdatedError" id="globalErrorContainer">
    <img class="error-icon"
         src="/assets/icons/20-dp-icon-notification-error-solid.svg"
         id="errIcon"
         alt="icon">
    <div class="form-error-global" id="documentNotUpdatedError">
      {{ 'errorMessages.mitarbeiter-document-not-updated-err-msg-W00034'|translate }}
    </div>
  </div>

  <div class="d-flex flex-row mb-10px" [ngClass]="{'mt-25px': !validationInfo.documentNotUpdatedError }">

    <div class="me-4">
      <app-date *ngIf="hasPruefungsDatum"
                [parentForm]="form"
                registerAs="pruefungsDatum"
                idString="{{headline + '-pruefungsDatum'}}"
                [required]="false"
                label="{{'mitarbeiter.nachweise.pruefungsdatum' | translate}}"
                (dateChange)="onDateChange($event)"
                [min]="date1900">
      </app-date>

      <app-date *ngIf="!hasPruefungsDatum"
                [parentForm]="form"
                registerAs="ablaufDatum"
                idString="{{headline + '-ablaufDatum'}}"
                [required]="false"
                label="{{'mitarbeiter.nachweise.ablaufsdatum' | translate}}"
                (dateChange)="onDateChange($event)"
                [min]="date1900">
      </app-date>

    </div>

    <div [style]="'margin-right: 18px'">
      <mat-form-field hideRequiredMarker appearance="fill" class="width-450">
        <mat-label>{{ 'mitarbeiter.nachweise.kontakt-person'|translate }}</mat-label>
        <input type="text" matInput appTrim formControlName="kontaktPerson" maxlength="255"
               (change)="checkValidation(null)">
        <mat-error *ngIf="kontaktPersonControl?.hasError('requiredWithTrim')">
          {{ 'errorMessages.required-W00010'|translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex flex-column">
      <input type="file" class="file-input" (change)="onNachweisFileSelected($event)" id="nachweisFileUpload"
             accept=".pdf, .jpg, .jpeg, .png, image/jpeg, image/png, application/pdf" #nachweisUpload>
      <input type="text" hidden formControlName="dateiName">
      <input type="text" hidden formControlName="fileUploadId">
      <div appDnd (fileDropped)="onImageDropped($event)" class="d-flex justify-content-center drop-area cursor-pointer" id="drop-area"
           (click)="(nachweisUpload.value = ''); nachweisUpload.click()">
        <span class="drop-area-label width-150">{{ 'mitarbeiter.nachweise.drop-area-label'|translate }}</span>
      </div>
      <div class="form-error width-200" *ngIf="nachweisUploadError" id="nachweisUploadError">{{ nachweisUploadError }}</div>
      <div class="form-error width-200" *ngIf="errorMsgUploadFile" id="{{errorId}}">
        {{ 'errorMessages.mitarbeiter-upload-files-technical-problem-msg-W00017'|translate }}
      </div>
    </div>

    <div class="d-flex flex-column ms-3" *ngIf="nachweisFile.dateiName" (click)="onDisplayNachweisFile()">
      <mat-spinner *ngIf="isUploadingFile" class="image-upload-spinner" id="{{spinnerId}}"></mat-spinner>
      <ng-container *ngIf="!isUploadingFile">
        <img class="action-icon icon-clickable"
             src="/assets/icons/ic-32-doc.svg"
             id="fileIcon"
             alt="icon">
        <div>
          <span class="action-label-prefix ellipsis icon-clickable" id="action-label-filename-prefix">{{ dateiNamePrefix }}</span>
          <span class="action-label-suffix icon-clickable" id="action-label-filename-suffix">{{ dateiNameSuffix }}</span>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="d-flex flex-row mb-10px" [style]="'margin-left: 185px'">
    <div class="me-auto me-4">
      <ng-container *ngIf="hasBedingung">
        <mat-form-field hideRequiredMarker appearance="fill" [style]="'width: 571px'">
          <mat-label>{{ 'mitarbeiter.nachweise.bedingungen'|translate }}</mat-label>
          <input type="text" matInput appTrim formControlName="bedingungen" maxlength="255" (change)="checkValidation(null)">
        </mat-form-field>
      </ng-container>
    </div>

  </div>

  <input type="number" hidden formControlName="id">
  <input type="text" hidden formControlName="kategorie">
  <input type="text" hidden formControlName="geprueft">

</div>

/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SicherungsUnternehmen { 
    /**
     * Eindeutige (technische) ID des Unternehmenes. IDs werden zwischen sicherungsunternehmen und ARGEn geteilt; Ein Sicherungsunternehmen und eine Arge können nie dieselbe ID haben
     */
    id?: number | null;
    /**
     * Eindeutige Id des Sicherungsunternehmens aus Smart, 10 Stellen mit führenden Nullen aufzufüllen
     */
    smartId: string;
    /**
     * Name des Sicherungsunternehmens
     */
    name?: string | null;
    /**
     * Ablaufdatum der Präqualifikation des Sicherungsunternehmens
     */
    ablaufdatumPraequali?: string | null;
    /**
     * Postleitzahl der Anschrift des Sicherungsunternehmens
     */
    plz?: string | null;
    /**
     * Stadt der Anschrift des Sicherungsunternehmens
     */
    stadt?: string | null;
    /**
     * Straße und Hausnummer der Anschrift des Sicherungsunternehmens
     */
    strasse?: string | null;
    /**
     * Vorname der Kontaktperson des Sicherungsunternehmens
     */
    kontaktPersonVorname?: string | null;
    /**
     * Nachname der Kontaktperson des Sicherungsunternehmens
     */
    kontaktPersonNachname?: string | null;
    /**
     * Emailadresse der Kontaktperson des Sicherungsunternehmens
     */
    kontaktPersonEmail?: string | null;
    /**
     * Telefonnr der Kontaktperson des Sicherungsunternehmens
     */
    kontaktPersonTelefonNr?: string | null;
    /**
     * Zustand des Sicherungsunternehmens in ElBa
     */
    status?: SicherungsUnternehmen.StatusEnum;
    /**
     * Datum ab dem das Sicherungsunternehmen in ElBa aktiv geschaltet ist
     */
    aktivAb?: string | null;
    /**
     * Datum ab dem das Sicherungsunternehmen in ElBa als gesperrt markiert wurde oder wird
     */
    sperrungAb?: string | null;
    /**
     * Beschreibung warum das Sicherungsnunternehmen in ElBa gesperrt wurde.
     */
    sperrungGrund?: string | null;
}
export namespace SicherungsUnternehmen {
    export type StatusEnum = 'ANGELEGT' | 'AKTIV' | 'GESPERRT';
    export const StatusEnum = {
        Angelegt: 'ANGELEGT' as StatusEnum,
        Aktiv: 'AKTIV' as StatusEnum,
        Gesperrt: 'GESPERRT' as StatusEnum
    };
}



<div class="d-flex hinweis">
  <span class="hinweis-icon"><img src="/assets/icons/24-dp-icon-action-info.svg" alt="hinweis"></span>
  <p>{{'mitarbeiter.atws-qualifikationen.hinweis-W00040'|translate}}</p>
</div>

<ng-container [formGroup]="$any(atwsQualifikationenFormArray)"
              *ngFor="let control of atwsQualifikationenFormArray?.controls; let idx = index">
  <ng-container [formGroupName]="idx">
    <app-atws-qualifikation #atwsComponent
      [parentForm]="control"
      [formArrayIndex]="idx"
      (displayFile)="onDisplayFile(idx)"
      (fileChange)="onFileChange($event, idx)"
    ></app-atws-qualifikation>
  </ng-container>
</ng-container>

/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Kategorie Enumeration eines Mitarbeiternachweises
 */
export type NachweisKategorie = 'SIPO_ERSTAUSBILDUNG' | 'SIPO_FIT' | 'SIAU_ERSTAUSBILDUNG' | 'SIAU_FIT' | 'PSYCHOLOGISCHE_EIGNUNG' | 'MEDIZINISCHE_EIGNUNG';

export const NachweisKategorie = {
    SipoErstausbildung: 'SIPO_ERSTAUSBILDUNG' as NachweisKategorie,
    SipoFit: 'SIPO_FIT' as NachweisKategorie,
    SiauErstausbildung: 'SIAU_ERSTAUSBILDUNG' as NachweisKategorie,
    SiauFit: 'SIAU_FIT' as NachweisKategorie,
    PsychologischeEignung: 'PSYCHOLOGISCHE_EIGNUNG' as NachweisKategorie,
    MedizinischeEignung: 'MEDIZINISCHE_EIGNUNG' as NachweisKategorie
};


/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Zustand des Mitarbeiters
 */
export type MitarbeiterZustand = 'INAKTIV' | 'AKTIV' | 'GESPERRT';

export const MitarbeiterZustand = {
    Inaktiv: 'INAKTIV' as MitarbeiterZustand,
    Aktiv: 'AKTIV' as MitarbeiterZustand,
    Gesperrt: 'GESPERRT' as MitarbeiterZustand
};


/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Definition des Sperrungtyps für Mitarbeiter. I.e. ob der Mitarbeiter vom FBF, Sachbearbeiter oder wegen der Unternehmensperre gesperrt ist.
 */
export type SperrungTypEnum = 'UNTERNEHMEN_GESPERRT' | 'GESPERRT_VON_FBF' | 'GESPERRT_VON_SACHBEARBEITER';

export const SperrungTypEnum = {
    UnternehmenGesperrt: 'UNTERNEHMEN_GESPERRT' as SperrungTypEnum,
    GesperrtVonFbf: 'GESPERRT_VON_FBF' as SperrungTypEnum,
    GesperrtVonSachbearbeiter: 'GESPERRT_VON_SACHBEARBEITER' as SperrungTypEnum
};


/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ArgeSicherungsUnternehmenRelation } from './argeSicherungsUnternehmenRelation';


export interface Arge { 
    /**
     * Eindeutige (technische) ID der Arge. IDs werden zwischen sicherungsunternehmen und ARGEn geteilt; Ein Sicherungsunternehmen und eine Arge können nie dieselbe ID haben
     */
    id?: number | null;
    /**
     * ID der Arge in der Form A-XXXXX. Wird vom Backend vergeben. Die Zahl ist nicht mit der technischen ID identisch.
     */
    argeId?: string | null;
    /**
     * Name der Arge - beginnt mit \'ARGE\' und wird von den beteiligten Unternehmen gewählt
     */
    name: string;
    /**
     * Ablaufdatum der Arge. Kann überschritten werden für den Fall einer Bauverzögerung etc.
     */
    ablaufdatum: string;
    /**
     * Liste der Sicherungsunternehmen, die an der ARGE beteiligt sind
     */
    sicherungs_unternehmen: Array<ArgeSicherungsUnternehmenRelation>;
    /**
     * Vorname des technischen Leiters der ARGE
     */
    technischerLeiterVorname?: string | null;
    /**
     * Nachname des technischen Leiters der ARGE
     */
    technischerLeiterNachname?: string | null;
    /**
     * Telefonnummer des technischen Leiters der ARGE
     */
    technischerLeiterTelefon?: string | null;
    /**
     * E-Mail Adresse des technischen Leiters der ARGE
     */
    technischerLeiterEmail?: string | null;
    /**
     * Zustand des Sicherungsunternehmens in ElBa
     */
    status: Arge.StatusEnum;
    /**
     * Enthält den Grund der Sperrung, wenn status == \'GESPERRT\'
     */
    sperrungGrund?: string | null;
}
export namespace Arge {
    export type StatusEnum = 'AKTIV' | 'GESPERRT';
    export const StatusEnum = {
        Aktiv: 'AKTIV' as StatusEnum,
        Gesperrt: 'GESPERRT' as StatusEnum
    };
}



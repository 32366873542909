import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EnvironmentVariables } from './configuration.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  constructor(private http: HttpClient) {
  }

  initialize(): () => Observable<unknown> {
    return () => {
      return this.http.get('/configuration/configuration.json')
        .pipe(
          tap(response => {
              const environmentVariables = response as EnvironmentVariables;
              environment.userPoolId = environmentVariables.userPoolId;
              environment.identityPoolRegion = environmentVariables.identityPoolRegion;
              environment.oauthServiceUrl = environmentVariables.oauthServiceUrl;
              environment.oauthIssuerUrl = environmentVariables.oauthIssuerUrl;
              environment.oauthTokenUrl = environmentVariables.oauthTokenUrl;
              environment.oauthLogoutUrl = environmentVariables.oauthLogoutUrl;
              environment.oauthServiceClientIdExterneMitarbeiter = environmentVariables.oauthServiceClientIdExterneMitarbeiter;
              environment.oauthServiceClientIdInterneMitarbeiter = environmentVariables.oauthServiceClientIdInterneMitarbeiter;
              environment.oauthServiceScopeExterneMitarbeiter = environmentVariables.oauthServiceScopeExterneMitarbeiter;
              environment.oauthServiceScopeInterneMitarbeiter = environmentVariables.oauthServiceScopeInterneMitarbeiter;
            }
          )
        );
    };
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DBBaseAbstractComponent } from '../../base/component-base.abstract.component';
import { FormControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '../../util/utils';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateComponent extends DBBaseAbstractComponent implements OnInit {

  @Input() registerAs = 'myDate';
  @Input() idString = 'myDate';

  @Input() set required(value: boolean) {
    if (value) {
      this.formControl?.setValidators([Validators.required]);
    } else {
      this.formControl?.setValidators([]);
    }
    this.formControl.updateValueAndValidity();
  }

  @Input() set error(errorInput: boolean) {
    this.errorFlag = errorInput;
    this.checkAndSetError();
  }

  @Input() label = 'myLabel';
  @Input() min: Date = Utils.date1900();
  @Input() max: Date = Utils.date3000();
  @Input() className = '';
  @Input() readonly = false;
  @Output() dateChange = new EventEmitter<string>();

  /**
   * Form
   */
  formControl: FormControl;
  value = '';

  private errorFlag = false;

  constructor(
    protected parentFormGroupDirective: FormGroupDirective,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly datePipe: DatePipe,
  ) {
    super(parentFormGroupDirective);
    this.formControl = this.createMainForm();
  }

  ngOnInit(): void {
    this.overrideFormFunctions();
    this.registerToForm(this.registerAs, this.formControl, -1, this.parentForm as UntypedFormGroup);

    this.formControl.statusChanges
      .pipe(filter(s => s == 'VALID'))
      .subscribe(() => this.onValid());
  }

  onBlur() {
    if (this.formControl.hasValidator(Validators.required) && !this.formControl?.hasError('required')
      && !this.value) {
      this.formControl.setErrors({ required: true });
    }
  }

  onInput(value: string) {
    this.value = value;
  }

  onDateChange() {
    if (this.value) {
      const regex = DateComponent.datePattern();
      if (!regex.test(this.value)) {
        this.formControl.setErrors({ pattern: true });
      } else if (this.formControl.value) {
        const date = new Date(this.formControl.value);
        date.setHours(0, 0, 0, 0);
        this.min.setHours(0, 0, 0, 0);
        this.max.setHours(0, 0, 0, 0);
        if (date < this.min) {
          this.formControl.setErrors({ matDatepickerMin: true });
        } else if (date > this.max) {
          this.formControl.setErrors({ matDatepickerMax: true });
        }
      }
    }
    this.dateChange.emit(this.value);
  }

  onKeydown(event: Event) {
    DateComponent.acceptOnlyNumbersAndPoint(event);
  }

  public getInputElement(target: EventTarget | null): HTMLInputElement {
    return target as HTMLInputElement;
  }
  
  private createMainForm(): FormControl {
    return this.formBuilder.control('');
  }

  private static acceptOnlyNumbersAndPoint(event: Event) {
    const e = <KeyboardEvent>event;
    if ((e.key === 'Delete') ||
      (e.key === 'Backspace') ||
      (e.key === 'Tab') ||
      (e.key === 'Escape') ||
      (e.key === 'NumpadEnter') ||
      (e.key === 'Enter') ||

      // Allow: Ctrl+A
      (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.key === 'v' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.key === 'x' && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.key === 'End') ||
      (e.key === 'Home') ||
      (e.key === 'ArrowLeft') ||
      (e.key === 'ArrowRight') ||
      (e.key === 'ArrowUp') ||
      (e.key === '.')) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (e.shiftKey || e.key < '0' || e.key > '9') {
      e.preventDefault();
    }
  }

  public static datePattern(): RegExp {
    return new RegExp('^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.(\\d{4})\\s*$');
  }

  private onValid() {
    this.checkAndSetError();
  }

  private checkAndSetError() {
    if (this.errorFlag && !this.formControl?.errors) {
      this.formControl?.setErrors({ errorExists: true });
    }
  }

  private overrideFormFunctions(): void {
    const setValue = this.formControl.setValue;
    this.formControl.setValue = (value: string, options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined; } | undefined): void => {
      this.value = Utils.mapDateToGermanString(this.datePipe, value) as string;
      setValue.apply(this.formControl, [value, options]);
    };

    const patchValue = this.formControl.patchValue;
    this.formControl.patchValue = (value: string, options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined; } | undefined): void => {
      this.value = Utils.mapDateToGermanString(this.datePipe, value) as string;
      patchValue.apply(this.formControl, [value, options]);
    };

    const reset = this.formControl.reset;
    this.formControl.reset = (value: string, options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined; } | undefined): void => {
      this.value = Utils.mapDateToGermanString(this.datePipe, value) as string;
      reset.apply(this.formControl, [value, options]);
    };
  }
}

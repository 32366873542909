import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MitarbeiterService } from '../service/mitarbeiter.service';
import { ActivatedRoute } from '@angular/router';
import { BackendError } from 'src/app/shared/base/model-base-abstract';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeComponent implements OnInit {

  imgUrl: string | ArrayBuffer | null = '';
  mitarbeiterId = '';
  errorMsg = '';

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly mitarbeiterService: MitarbeiterService,
  ) {

  }

  ngOnInit(): void {
    this.route.params
      .subscribe(params => {
          if (params?.id) {
            this.mitarbeiterId = params.id;
            this.loadMitarbeiterBarcode(this.mitarbeiterId);
          }
        }
      );
  }

  private loadMitarbeiterBarcode(mitarbeiterId: string) {
    this.mitarbeiterService.loadMitarbeiterBarcode(mitarbeiterId).subscribe(
      (blob: Blob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            this.imgUrl = e.target.result;
            this.changeDetectorRef.detectChanges();
          }
        };
        reader.readAsDataURL(blob);
      },
      error => {
        console.log('Error by loading Mitarbeiter-Barcode: ', error);
        this.displayTechnicalError(error);
      });
  }

  private displayTechnicalError(err: BackendError) {
    this.errorMsg = 'errorMessages.barcode-W00048';
    if (err && err.error && err.error.titel) {
      this.errorMsg = err.error.titel;
      if (err.error.fehlerCode) {
        this.errorMsg += ' (FehlerCode: ' + err.error.fehlerCode + ').';
      }
    }
    this.changeDetectorRef.detectChanges();
  }
}

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { throwIfAlreadyLoaded } from './module-import-guard';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuthModule } from './auth/auth.module';
import { RouterModule } from '@angular/router';
import { ConfigurationService } from './configuration-service/configuration.service';
import { Observable } from 'rxjs';
import { SecurityTxtRedirectModule } from './security-txt-redirect/security-txt-redirect.module';
import { ImpressumDialogComponent } from './footer/impressum-dialog/impressum-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DatenschutzDialogComponent } from './footer/datenschutz-dialog/datenschutz-dialog.component';
import {MatIconModule} from "@angular/material/icon";
import { WINDOW_PROVIDERS } from './window-providers/window-providers';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeAppFactory(http: HttpClient, environmentService: ConfigurationService): () => Observable<unknown> {
  return environmentService.initialize();
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    MatIconModule,
  ],
  exports: [
    AuthModule,
    SecurityTxtRedirectModule,
    FooterComponent,
    HeaderComponent,
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    ImpressumDialogComponent,
    DatenschutzDialogComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [HttpClient, ConfigurationService]
    },
    TranslateService,
    WINDOW_PROVIDERS
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

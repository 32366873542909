/**
 * Legal Notice! DB Systel GmbH proprietary Licence!
 *
 * Copyright (C) 2018 DB Systel GmbH
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * This code is protected by copyright law and is the exclusive property of
 * DB Systel GmbH; Juergen-Ponto-Platz 1; D-60329 Frankfurt am Main; Germany;
 * http://www.dbsystel.de/
 *
 * Consent to use ("licence") shall be granted solely on the basis of a
 * written licence agreement signed by the customer and DB Systel GmbH. Any
 * other use, in particular copying, redistribution, publication or
 * modification of this code without written permission of DB Systel GmbH is
 * expressly prohibited.
 *
 * In the event of any permitted copying, redistribution or publication of
 * this code, no changes in or deletion of author attribution, trademark
 * legend or copyright notice shall be made.
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthConfigService } from './auth-config.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AmplifyWebAuthnService } from '../webauthn/amplify-web-authn.service';

enum AuthProviderType {
  INTERNE_MITARBEITER = 'INTERNE_MITARBEITER',
  EXTERNE_MITARBEITER = 'EXTERNE_MITARBEITER'
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly SELECTED_AUTH_PROVIDER_KEY = 'SelectedAuthenticationProvider';
  private readonly selfSignoutUrl: string;
  private readonly apiBaseUrl = environment.apiUrl;

  constructor(
    private readonly router: Router,
    private readonly authentication: AuthConfigService,
    private readonly oAuthService: OAuthService,
    private readonly amplfyWebAuthnService: AmplifyWebAuthnService,
    private readonly http: HttpClient
  ) {
    this.selfSignoutUrl = this.apiBaseUrl.concat('/benutzern/self/signout');
  }

  get isProviderSelected(): boolean {
    const providerTyp = localStorage.getItem(this.SELECTED_AUTH_PROVIDER_KEY);
    return !!providerTyp;
  }

  get isInternerMitarbeiter(): boolean {
    return this.selectedProvider === AuthProviderType.INTERNE_MITARBEITER;
  }

  get isExternerMitarbeiter(): boolean {
    return this.selectedProvider === AuthProviderType.EXTERNE_MITARBEITER;
  }

  isUserLoggedIn(): boolean {
    if (this.isInternerMitarbeiter) {
      return this.oAuthService.hasValidAccessToken();
    } else {
      return this.amplfyWebAuthnService.isUserLoggedIn();
    }

  }

  login(): void {
    if (this.isInternerMitarbeiter) {
      this.oAuthService.initCodeFlow();
    } else {
      this.router.navigateByUrl('/webauthn/login');
    }
  }


  setInternerMitarbeiter(): void {
    this.selectedProvider = AuthProviderType.INTERNE_MITARBEITER;
    this.oAuthService.logOut(true);
    this.authentication.configure(environment.oauthServiceClientIdInterneMitarbeiter, environment.oauthServiceScopeInterneMitarbeiter);
    this.oAuthService.setupAutomaticSilentRefresh();
    this.oAuthService.initCodeFlow();
  }

  setExternerMitarbeiterAndNavigateToLogin(): void {
    this.setExternerMitarbeiter();
    this.router.navigateByUrl('/webauthn/login');
  }

  setExternerMitarbeiter(): void {
    this.selectedProvider = AuthProviderType.EXTERNE_MITARBEITER;
  }

  getAccessToken(): Observable<string> {
    if (this.isInternerMitarbeiter) {
      return of(this.oAuthService.getAccessToken());
    } else {
      return this.amplfyWebAuthnService.getAccessToken();
    }
  }

  getIdToken(): Observable<string> {
    if (this.isInternerMitarbeiter) {
      return of(this.oAuthService.getIdToken());
    } else {
      return this.amplfyWebAuthnService.getIdToken();
    }
  }

  init(): void {
    if (this.isProviderSelected) {
      let cliendId;
      let scope;
      if (this.isInternerMitarbeiter) {
        cliendId = environment.oauthServiceClientIdInterneMitarbeiter;
        scope = environment.oauthServiceScopeInterneMitarbeiter;
      } else {
        cliendId = environment.oauthServiceClientIdExterneMitarbeiter;
        scope = environment.oauthServiceScopeExterneMitarbeiter;
      }
      if (this.isInternerMitarbeiter) {
        this.authentication.configure(cliendId, scope);
        this.oAuthService.setupAutomaticSilentRefresh();
      }
    }
  }

  logOutAndNavigate() {
    this.logOut();
    this.router.navigateByUrl('/auth/provider-selection');
  }

  logOut() {
    if (this.isInternerMitarbeiter) {
      const noRedirectToLogoutUrl = this.isInternerMitarbeiter;
      this.oAuthService.logOut(noRedirectToLogoutUrl);
      sessionStorage.clear();
    } else {
      // In this case the user might be globally logged out (from backend for example).
      this.amplfyWebAuthnService.signOut();
    }

    // In case of Cognito we do not need the statement (initCodeFlow()): this.oAuthService.initCodeFlow()
  }

  selfSignout(): Observable<unknown> {
    return this.http.post(this.selfSignoutUrl, {});
  }

  private get selectedProvider(): AuthProviderType | null {
    const providerTyp = localStorage.getItem(this.SELECTED_AUTH_PROVIDER_KEY);
    if (!providerTyp) {
      return null;
    }

    if (AuthProviderType.EXTERNE_MITARBEITER === providerTyp) {
      return AuthProviderType.EXTERNE_MITARBEITER;
    } else if (AuthProviderType.INTERNE_MITARBEITER === providerTyp) {
      return AuthProviderType.INTERNE_MITARBEITER;
    } else {
      return null;
    }
  }

  private set selectedProvider(authProviderType: AuthProviderType | null) {
    if (authProviderType) {
      localStorage.setItem(this.SELECTED_AUTH_PROVIDER_KEY, authProviderType);
    }
  }

}

import { NgModule } from '@angular/core';

import { DBTooltipComponent } from './tooltip.component';
import { DBTooltipDirective } from './tooltip.directive';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

/**
 * DB Tooltip Module
 */
@NgModule( {
    declarations: [
        DBTooltipDirective,
        DBTooltipComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
    ],
    exports: [
        DBTooltipDirective,
    ]
} )
export class DBTooltipModule {}

/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BenutzerRolle { 
    /**
     * Name der Rolle für den Benutzer. Ein Benutzer kann ein Mitarbeiter (SiPo, SiAu), Sachbearbeiter oder fachliche Betreibsführung sein.
     */
    name: BenutzerRolle.NameEnum;
}
export namespace BenutzerRolle {
    export type NameEnum = 'FBF' | 'SACHBEARBEITER' | 'SIPO' | 'SIAU' | 'MITARBEITER';
    export const NameEnum = {
        Fbf: 'FBF' as NameEnum,
        Sachbearbeiter: 'SACHBEARBEITER' as NameEnum,
        Sipo: 'SIPO' as NameEnum,
        Siau: 'SIAU' as NameEnum,
        Mitarbeiter: 'MITARBEITER' as NameEnum
    };
}



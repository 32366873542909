import { AbstractControl, FormGroupDirective, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

/**
 * Abstract Form Composition class, enabling the connection of compositional form components to the Angular Forms module
 */
@Component({
  selector: 'app-base',
  template: `
    <p>
      component base
    </p>
  `,
  styles: [
  ]
})
export abstract class DBBaseAbstractComponent {

  /**
   * (optional) Index of array when parentForm is a FormArray
   * Index of array when parentForm is a FormArray and
   * when this FormArray is the outer Form of the component template
   */
  @Input()
  public formArrayIndex = 0;

  /**
   * (optional) parentForm in case that the parent is a FormArray
   */
  @Input()
  public parentForm: AbstractControl | null = null;

  /**
   * Reference to the parent form group directive (and thus the form group control itself)
   */
  protected readonly parentFormGroupDirective: FormGroupDirective | null;

  /**
   * Constructor
   *
   * @param parentFormGroupDirective - Parent form group directive
   */
  constructor(parentFormGroupDirective: FormGroupDirective | null) {
    this.parentFormGroupDirective = parentFormGroupDirective;
  }

  /**
   * Register the form composition to the parent form (to be called first in the on-init lifecycle hook)
   *
   * @param name        - Name of the form element
   * @param formElement - Form element itself
   * @param {number} formArrayIndex - (optional) Index of array when parentForm is a FormArray and
   *                    when this FormArray is the outer Form of the component template (e.g. see ubo component).
   *
   * @param {UntypedFormGroup} parentForm - (optional) parent form if a component is inserted in a FormArray
   *                    and this FormArray is not the outer form of the component
   * @protected
   */
  protected registerToForm(name: string, formElement: AbstractControl, formArrayIndex?: number, parentForm?: UntypedFormGroup | null): void {

    // Skip if there is no parent form
    if (this.parentFormGroupDirective === null) {
      return;
    }

    let initialOuterState: unknown;
    let parentFormCalculated: UntypedFormGroup;
    if (this.parentFormGroupDirective.control instanceof UntypedFormArray && Number.isFinite(formArrayIndex) && 
      (formArrayIndex != null && formArrayIndex > -1)) {
      const formArray: UntypedFormArray = this.parentFormGroupDirective.control as UntypedFormArray;
      parentFormCalculated = (formArray.at(formArrayIndex) as UntypedFormGroup);
    } else if (parentForm) {
      parentFormCalculated = parentForm;
    } else {
      // (Re-)Add form element
      parentFormCalculated = this.parentFormGroupDirective.control;
    }

    // If a form element is already registered at the given name, remove it first (and save its value)
    // eslint-disable-next-line no-prototype-builtins
    if (parentFormCalculated.controls.hasOwnProperty(name)) {

      // Save initial outer state
      initialOuterState = parentFormCalculated.controls[name].value;

      // Remove form element
      parentFormCalculated.removeControl(name);
    }
    parentFormCalculated.addControl(name, formElement);

    // Patch the value
    if (initialOuterState) {
      formElement.patchValue(initialOuterState);
    }

  }

  /**
   * Unregister the form composition from the parent form (to be called in the on-destroy lifecycle hook)
   *
   * @param name - Name of the form element
   */
  protected unregisterFromForm(name: string): void {
    // Skip if there is no parent form
    if (this.parentFormGroupDirective === null) {
      return;
    }

    // Reset if it exists and clear validators
    if (this.parentFormGroupDirective.control &&
      this.parentFormGroupDirective.control.controls &&
    // eslint-disable-next-line no-prototype-builtins
    this.parentFormGroupDirective.control.controls.hasOwnProperty(name)) {
      this.parentFormGroupDirective.control.removeControl(name);
    }

  }
}

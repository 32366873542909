<div [formGroup]="form" class="atws-qualifikation" id="atwsQualifikationForm">

  <div class="d-flex flex-row justify-content-between">
    <h3 [ngClass]="{'elm-headline move-left': hasVerifyMarker(),'elm-headline-gray': !hasVerifyMarker() }">
      <img *ngIf="hasVerifyMarker()"
           class="verifyMarker"
           src="/assets/icons/oval.svg"
           id="changedDot"
           alt="icon">
      {{ headline |translate }}
      <img [appTooltip]="infoTooltipTemplate"
           class="info-icon"
           src="/assets/icons/20-dp-icon-action-info-solid.svg"
           id="infoIcon"
           alt="icon">
    </h3>
    <ng-template #infoTooltipTemplate>
          <span id="infoText">
            <span>{{ infoTooltipText|translate }}</span><br>
          </span>
    </ng-template>
  </div>

  <div class="d-flex global-error" *ngIf="validationInfo.documentNotUpdatedError" id="globalErrorContainer">
    <img class="error-icon"
         src="/assets/icons/20-dp-icon-notification-error-solid.svg"
         id="errIcon"
         alt="icon">
    <div class="form-error-global" id="documentNotUpdatedError">
      {{ 'errorMessages.mitarbeiter-document-not-updated-err-msg-W00034'|translate }}
    </div>
  </div>

  <div class="d-flex flex-row mb-3" [ngClass]="{'mt-25px': !validationInfo.documentNotUpdatedError }">

    <div class="me-4">
      <app-date [parentForm]="form"
                registerAs="pruefungsDatum"
                idString="{{headline + '-pruefungsDatum'}}"
                [required]="false"
                label="{{'mitarbeiter.atws-qualifikationen.pruefungsdatum' | translate}}"
                (dateChange)="onDateChange($event)"
                [min]="date1900">
      </app-date>
    </div>

    <div [style]="'margin-right: 18px'">
      <mat-form-field hideRequiredMarker appearance="fill" class="width-450">
        <mat-label>{{ 'mitarbeiter.atws-qualifikationen.kontakt-person'|translate }}</mat-label>
        <input type="text" matInput appTrim formControlName="kontaktPerson" maxlength="255"
               (change)="checkValidation(null)">
        <mat-error *ngIf="kontaktPersonControl?.hasError('requiredWithTrim')">
          {{ 'errorMessages.required-W00010'|translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex flex-column">
      <input type="file" class="file-input" (change)="onFileSelected($event)" id="fileUpload"
             accept=".pdf, .jpg, .jpeg, .png, image/jpeg, image/png, application/pdf" #qualifikationUpload>
      <input type="text" hidden formControlName="dateiName">
      <input type="text" hidden formControlName="fileUploadId">
      <div appDnd (fileDropped)="onImageDropped($event)" class="d-flex justify-content-center drop-area cursor-pointer" id="drop-area"
           (click)="(qualifikationUpload.value = ''); qualifikationUpload.click()">
        <span class="drop-area-label width-150">{{ 'mitarbeiter.atws-qualifikationen.drop-area-label'|translate }}</span>
      </div>
      <div class="form-error width-200" *ngIf="uploadError" id="atwsUploadError">{{ uploadError }}</div>
      <div class="form-error width-200" *ngIf="errorMsgUploadFile" id="{{errorId}}">
        {{ 'errorMessages.mitarbeiter-upload-files-technical-problem-msg-W00017'|translate }}
      </div>
    </div>

    <div class="d-flex flex-column ms-3" *ngIf="atwsQualifikationFile.dateiName" (click)="onDisplayFile()">
      <mat-spinner *ngIf="isUploadingFile" class="image-upload-spinner" id="{{spinnerId}}"></mat-spinner>
      <ng-container *ngIf="!isUploadingFile">
        <img class="action-icon icon-clickable"
             src="/assets/icons/ic-32-doc.svg"
             id="fileIcon"
             alt="icon">
        <div class="">
          <span class="action-label-prefix ellipsis icon-clickable" id="action-label-filename-prefix">{{ dateiNamePrefix }}</span>
          <span class="action-label-suffix icon-clickable" id="action-label-filename-suffix">{{ dateiNameSuffix }}</span>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="d-flex flex-row" [style]="'margin-bottom: 60px'">
    <div class="me-5">
      <mat-checkbox color="primary" formControlName="planerPlanpruefer" (change)="onChangePlanerPlanpruefer($event)">
        {{ 'mitarbeiter.atws-qualifikationen.planer-planpruefer'|translate }}
      </mat-checkbox>
    </div>
    <div class="me-5">
      <mat-checkbox color="primary" formControlName="monteur" (change)="onChangeMonteur($event)">
        {{ 'mitarbeiter.atws-qualifikationen.monteur'|translate }}
      </mat-checkbox>
    </div>
    <div class="me-5">
      <mat-checkbox color="primary" formControlName="bediener" (change)="onChangeBediener()">
        {{ 'mitarbeiter.atws-qualifikationen.bediener'|translate }}
      </mat-checkbox>
    </div>
  </div>

  <input type="number" hidden formControlName="id">
  <input type="text" hidden formControlName="kategorie">
  <input type="text" hidden formControlName="geprueft">

</div>

<main class="elba-outer-container d-flex justify-content-center" role="main">

  <div class="d-flex flex-column">

    <div class="barcode" id="barcodeForm">

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb-gray">
          <li class="breadcrumb-item">
            <a href="/mitarbeiter/{{mitarbeiterId}}">{{'mitarbeiter.href'|translate}} {{mitarbeiterId | id : 5}}</a>
          </li>
          <li class="breadcrumb-item active"
              aria-current="page">{{  'mitarbeiter.barcode' | translate }}</li>
        </ol>
      </nav>

      <img *ngIf="!errorMsg" class="img" src="{{imgUrl}}" alt="img">

      <div class="err-msg-box">
        <app-error-msg [text1]="errorMsg"></app-error-msg>
      </div>
    </div>
  </div>
</main>

/**
 * elba
 * API-Endpunkte für den Elektronischen Befähigunsausweis (ElBa). Diese Datei dient nur zur Endpunkt- und Schema-Definition. Servers und security Definitionen sind _nicht_ relevant! Wir geben die nur an, weil es zum Generieren der API und zum Compilieren des Codes und des Komponententests gebraucht wird! 
 *
 * The version of the OpenAPI document: 1
 * Contact: DB.SYSTEL.Nukleus.TeamUnicorn@deutschebahn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SicherungsUnternehmen } from './sicherungsUnternehmen';


export interface Sachbearbeiter { 
    /**
     * Eindeutige ID des Sachbearbeiters im System
     */
    id?: number | null;
    /**
     * Vorname des Sachbearbeiters
     */
    vorname: string;
    /**
     * Nachname des Sachbearbeiters
     */
    nachname: string;
    /**
     * Geburtsdatum des Sachbearbeiters
     */
    geburtsDatum: string;
    /**
     * E-Mailadresse des Sachbearbeiters
     */
    email: string | null;
    /**
     * Telefonnr des Sachbearbeiters
     */
    telefonNr?: string;
    /**
     * Zustand des Sachbearbeiters in ElBa
     */
    status?: Sachbearbeiter.StatusEnum;
    /**
     * Datum ab dem das Sachbearbeiter in ElBa aktiv geschaltet ist
     */
    aktivAb?: string | null;
    /**
     * Datum ab dem das Sachbearbeiter in ElBa als gesperrt markiert wurde oder wird
     */
    sperrungAb?: string | null;
    /**
     * Beschreibung warum das Sicherungsnunternehmen in ElBa gesperrt wurde.
     */
    sperrungGrund?: string | null;
    sicherungsUnternehmen?: Array<SicherungsUnternehmen>;
}
export namespace Sachbearbeiter {
    export type StatusEnum = 'ANGELEGT' | 'AKTIV' | 'GESPERRT';
    export const StatusEnum = {
        Angelegt: 'ANGELEGT' as StatusEnum,
        Aktiv: 'AKTIV' as StatusEnum,
        Gesperrt: 'GESPERRT' as StatusEnum
    };
}


